import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import TreeItem from '@material-ui/lab/TreeItem';
import { IStepStateProp, IStepState } from '../appState';
import { withUserContext, IUserProp } from './UserContext';
import StepsTreeItem from './StepTreeItem';

const useStyles = makeStyles({
  root: {
    // height: 216,
    flexGrow: 1,
    // maxWidth: 400,
  },
});

type IProps = IStepStateProp
  & { stateStepDetail: IStepState }
  & IUserProp
  ;

const Steps: React.FC<IProps> = ({ state, stateStepDetail, user }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(['']);

  const onToggleExpanded = (event: React.ChangeEvent<{}>, nodes: string[]) => {
    setExpanded(nodes);
  };

  return (
    <>
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        onNodeToggle={onToggleExpanded}
      >
        <StepsTreeItem state={state.steps} stateStepDetail={stateStepDetail} parentStepSelect={() => void 0} />
      </TreeView>
    </>
  );
}

export default withUserContext(Steps);
