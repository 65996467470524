import { IDocStep } from "./if";

const SYM_STATE = Symbol("SYM_STATE");
const SYM_STEPS = Symbol("SYM_STEPS");

class StateBase<T> {
  private [SYM_STATE]: [T, React.Dispatch<React.SetStateAction<T>>];
  set _(value: [T, React.Dispatch<React.SetStateAction<T>>]) { this[SYM_STATE] = value }
  get _() { return this[SYM_STATE]; }
  get val() { return this[SYM_STATE] && this[SYM_STATE][0]; }
  set val(value: T) { this[SYM_STATE][1](value); }
}

export class IStepState extends StateBase<IDocStep> {
  private [SYM_STEPS]: any;
  get steps() {
    if (!this[SYM_STEPS]) this[SYM_STEPS] = new IStepState();
    return this[SYM_STEPS] as IStepState;
  }
}

export interface IStepStateProp {
  state: IStepState
}

class AppState extends StateBase<null> {
  steps = new IStepState();
  step = new IStepState();
}

export const appState = new AppState();
