import { functions } from '../config/constants';
import { IDocStep, IDBCmds, IMergeStep, IUpdateStep } from '../if';

export const StepDummy: IDocStep = {
  id: '',
  idParent: '',
  uidOrigin: '',
  permissions: {},
  body: {
  },
  subSteps: [],
};

export async function onMergeStep(step: IMergeStep) {
  const result: { data: [{ MergeStep: IDocStep }] } = await functions.httpsCallable('db')([{ MergeStep: step }] as IDBCmds);
  return result.data[0].MergeStep;
}

const debouceStepUpdate: { [id: string]: any } = {};

export async function onUpdateStep(step: IUpdateStep) {
  const key = step.id + Object.keys(step.body).toString();
  clearTimeout(debouceStepUpdate[key]);
  debouceStepUpdate[key] = setTimeout(() => {
    delete debouceStepUpdate[key];
    functions.httpsCallable('db')([{ UpdateStep: step }] as IDBCmds);
  }, 2000);
}

export async function getStep(id?: string) {
  const result: { data: [{ GetStep: IDocStep }] } = await functions.httpsCallable('db')([{ GetStep: { id } }] as IDBCmds);
  return result.data[0].GetStep;
}

export async function deleteStep(id: string) {
  const result: { data: [{ DeleteStep: IDocStep }] } = await functions.httpsCallable('db')([{ DeleteStep: { id } }] as IDBCmds);
  return result.data[0].DeleteStep;
}

export async function onEcho() {
  const result = await functions.httpsCallable('db')([{ Echo: 'aaaaaaaaaaaaaaaaaaa' }]);
  return result;
}
