import { DB } from '../config/constants';
import { User } from 'firebase';
import { dateString } from '../utils/utils';
import { IUser } from '../if';

export async function onUserLoad({ uid, displayName, photoURL, email }: User) {
  if (!uid) return null;

  const docRef = DB.collection('users').doc(uid);

  await docRef.set({
    uid,
    displayName,
    photoURL,
    email,
    timeLastLoaded: Date.now(),
    timeLastLoadedStr: dateString(),
    permissions: {},
  } as IUser, { merge: true });

  const doc = await docRef.get();
  if (!doc.exists) return null;

  return doc.data() as IUser;
}
