import React, { useState } from 'react';
import { JournalEntry as J } from './Journal';
import Question from './Question';
import { IStepStateProp } from '../appState';
import { IDocStep, IStep } from '../if';
import { onUpdateStep } from '../db/step';

const Step: React.FC<IStepStateProp> = ({ state }) => {
  const [v, setV] = state._ = useState({ body: {} } as IDocStep);
  const ps = (name: keyof IStep) => ({
    name,
    value: v.body[name] || '',
    setValue: (value: string) => {
      setV({ ...v, body: { ...v.body, [name]: value } });
      onUpdateStep({ id: v.id, body: { [name]: value } });
    },
  });

  const vv = v.body;

  if (!v.id) return null;

  return <>
    <Question question="What's the goal?  'The goal is to ...'" parentState={ps('name')} autoFocus={true} />
    {vv.name && <Question question="When? 'It shall be done by ...'" parentState={ps('endTime')} />}
    {vv.endTime && <Question question="Name the target object/person affected by the goal completion.  (if this is youself then enter your name)" parentState={ps('subject')} />}
    {vv.subject && <Question question={`On completion what state/property/condition will the ${vv.subject} be in:`} parentState={ps('endState')} />}
    {vv.endState && <Question question="Consiering the goal is reached, what important thing does it enable?  'It will allow to ...':" parentState={ps('motiv')} />}
    {vv.motiv && <Question question={`What state/condition is ${vv.subject} currently in?  'It is ...'`} parentState={ps('beginState')} />}
    {vv.beginState && <>
      <J>Right now the {vv.subject} is {vv.beginState} and your goal is get the {vv.subject} to be {vv.endState} by {vv.endTime} in order to {vv.motiv}.</J>
      <J>Let me help you with that!</J>
      <J>So the goal is to getting the {vv.subject} {vv.endState}.  {vv.name} will accomplish it?</J>
      <J>So to {vv.name} means getting the {vv.subject} from {vv.beginState} state to {vv.endState} state.</J>
      <J>Let's define the steps to {vv.name}.</J>
    </>}
  </>;
}

export default Step;
