import React from 'react';
import { withUserContext, IUserProp } from './UserContext';
import { IconButton, Menu, MenuItem, makeStyles, createStyles, Theme } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import firebase from 'firebase';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
    user: {
    },
  }),
);

const UserProfile: React.FC<IUserProp> = ({ user }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const onSignout = () => {
    firebase.auth().signOut();
    setAnchorEl(null);
  }

  return (<>
    <div className={classes.user}>Hello, {user.displayName}</div>
    <div className={classes.root}>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={onSignout}>Signout</MenuItem>
      </Menu>
    </div>
  </>);
}

export default withUserContext(UserProfile);
