import React from 'react';
import ReactDOM from 'react-dom';
import './config/constants'
import './index.css';
import { Router, Route } from "react-router";
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from 'history';
import { UserContext } from './compoments/UserContext';
import firebase from 'firebase';
import { AuthProviders } from './config/constants';
import { Button } from '@material-ui/core';
import { onUserLoad } from './db/user';
import { IUser } from './if';

const debug = null;//React.createElement(require('./compoments/Debug').Debug, {});

const history = createBrowserHistory();

const Index = () => {
  const [user, setUser] = React.useState<IUser | null>();

  React.useEffect(() => {
    const unsub = firebase.auth().onAuthStateChanged(async user => {
      setUser(null);
      if (!user) return;
      const profile = await onUserLoad(user);
      setUser(profile);
    });

    return unsub;
  }, []);

  if (user === void 0) {
    return <div>Loading</div>;
  }

  if (!user) {
    return <>
      {debug}
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
        firebase.auth().signInWithRedirect(AuthProviders.Google).then(result => {
          // firebase.auth().signInWithPopup(AuthProviders.Google).then(result => {
          console.log(result);
        }).catch(err => {
          console.log(err);
        });
      }}>Login with Google</Button>
    </>;
  }

  return <>
    {debug}
    <UserContext.Provider value={user}>
      <Router history={history}>
        <Route path="/" component={App} />
      </Router>
    </UserContext.Provider>
  </>;
}

ReactDOM.render(<Index />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
