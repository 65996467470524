import React, { useEffect } from 'react';
import TreeItem from '@material-ui/lab/TreeItem';
import { getStep, StepDummy, deleteStep, onMergeStep, onUpdateStep } from '../db/step';
import { IStepStateProp, IStepState } from '../appState';
import { IStepHeader } from '../if';
import { withUserContext, IUserProp } from './UserContext';
import { Box, Typography, makeStyles, createStyles, Theme } from '@material-ui/core';
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import ArrowUp from '@material-ui/icons/ArrowDropUp';
// import ArrowLeft from '@material-ui/icons/ArrowLeft';
// import ArrowRight from '@material-ui/icons/ArrowRight';
import DeleteIcon from '@material-ui/icons/Delete';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import shortid from 'shortid';

type IDecInc = {
  dec?: number;
  inc?: number;
};

type IProps = IStepStateProp
  & { stateStepDetail: IStepState }
  & { parentStepSelect: () => void }
  & IUserProp
  & { header?: IStepHeader }
  & { decInc?: IDecInc }
  ;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
    typoText: {
      verticalAlign: 'bottom',
      display: 'inline-flex',
      alignItems: 'center',
    },
    button: {
      margin: theme.spacing(0),
    },
  }),
);

const Comp: React.FC<IProps> = ({ state, stateStepDetail, user, header = { id: '', name: 'your goals' }, parentStepSelect, decInc = {} }) => {
  const classes = useStyles();
  const { id, name } = header;
  const [v, setV] = state._ = React.useState(StepDummy);
  useEffect(() => {
    void async function () {
      const step = await getStep(id);
      setV(step);
    }();
  }, [id, setV]);

  if (!v || !v.subSteps) return <div>loading...</div>;

  const onStepSelect = async () => {
    const result = await getStep(id);
    setV(result);
    stateStepDetail.val = result;
  };

  const onNew = async () => {
    const nameBase = id ? 'step' : 'goal';
    let n = 0;
    let name: string;
    const names = v.subSteps.map(header => header.name);
    for (name = `${nameBase} ${++n}`; names.includes(name); name = `${nameBase} ${++n}`);
    const result = await onMergeStep({ idParent: id, id: shortid.generate(), body: { name } });
    stateStepDetail.val = result;
    setV(await getStep(id));
  };
  // const onLeft = () => {
  // };
  // const onRight = () => {
  // };
  const onUp = async () => {
    await onUpdateStep({ id, body: {}, order: decInc.dec });
    await parentStepSelect();
  };
  const onDown = async () => {
    await onUpdateStep({ id, body: {}, order: decInc.inc });
    await parentStepSelect();
  };
  const onDelete = async () => {
    const result = await deleteStep(id);
    if (result.error) return;
    await parentStepSelect();
  };

  const isActive = (stateStepDetail && stateStepDetail.val && stateStepDetail.val.id === id);

  const stop = (f: () => void) => (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    f();
  }

  const subSteps = v.subSteps.sort((a, b) => ((a && a.order) || 0) - ((b && b.order) || 0));
  const U = subSteps.length - 1;
  const subStepsMv = subSteps.map((step, ix) => ({
    dec: ix === 0 ? void 0 : ix === 0 + 1 ? subSteps[ix - 1].order - 1 : (subSteps[ix - 2].order + subSteps[ix - 1].order) / 2,
    inc: ix === U ? void 0 : ix === U - 1 ? subSteps[ix + 1].order + 1 : (subSteps[ix + 2].order + subSteps[ix + 1].order) / 2,
  }));

  return (
    <TreeItem
      nodeId={id}
      label={
        <Box
          display="flex"
          onClick={stop(onStepSelect)}
        >
          {v.subSteps.length === 0 && <FiberNewIcon visibility="hidden" />}
          <FiberNewIcon className={classes.button} onClick={stop(onNew)} />
          {/* {id && <ArrowLeft className={classes.button} onClick={stop(onLeft)} />}
          {id && <ArrowRight className={classes.button} onClick={stop(onRight)} />} */}
          {id && <ArrowUp visibility={decInc.dec ? '' : 'hidden'} className={classes.button} onClick={stop(onUp)} />}
          {id && <ArrowDown visibility={decInc.inc ? '' : 'hidden'} className={classes.button} onClick={stop(onDown)} />}
          {id && <DeleteIcon className={classes.button} onClick={stop(onDelete)} />}
          <Typography className={classes.typoText} style={isActive ? { backgroundColor: "lightgray" } : {}}>
            {(v.body.name || name || '[new]')}
          </Typography>
        </Box>
      }
    >
      {subSteps.map((header, ix) =>
        <StepTreeItem key={header.id} state={state.steps} stateStepDetail={stateStepDetail} header={header} parentStepSelect={onStepSelect} decInc={subStepsMv[ix]} />
      )}
    </TreeItem>
  );
}

const StepTreeItem = withUserContext(Comp)
export default StepTreeItem;
