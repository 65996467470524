import React from 'react';

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

function JournalEntry({ children }: Props) {
  const c = Array.isArray(children) ? children : [children];
  return (
    <p>
      {c.map((child, ix) => ix % 2 ? <b key={ix}>{child}</b> : child)}
    </p>
  );
}

export { JournalEntry };