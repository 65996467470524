import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(1),
    },
  }),
);

interface Props {
  question: string;
  parentState: { value: string, setValue: any };
  autoFocus?: boolean;
}

export default function InputAdornments({ question, parentState, autoFocus }: Props) {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //setValue(event.target.value);
    parentState.setValue(event.target.value);
  };

  return (
    <div className={classes.root}>
      <FormControl fullWidth className={classes.margin}>
        <InputLabel htmlFor="standard-adornment-amount">{question}</InputLabel>
        <Input
          id="standard-adornment-amount"
          value={parentState.value}
          onChange={handleChange}
          autoFocus={autoFocus}
        />
      </FormControl>
    </div>
  );
}
