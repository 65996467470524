import firebase from 'firebase'

const config = {
    apiKey: "AIzaSyA61uVfwrG6QZYbGAFdp8SJWKwKMTm8pp4",
    authDomain: "next-ad1f8.firebaseapp.com",
    projectId: "next-ad1f8",
};

firebase.initializeApp(config);

export const DB = firebase.firestore();
export const DocsStep = DB.collection('steps');

export const AuthProviders = {
    Google: new firebase.auth.GoogleAuthProvider(),
};

// export const ref = firebase.database().ref();
export const firebaseAuth = firebase.auth;

export const functions = firebase.functions();

if (process.env.NODE_ENV !== "production")
    firebase.functions().useFunctionsEmulator('http://localhost:5001')

test.toString();
function test() {
    console.log('id=' + DocsStep.doc().id);
    functions.httpsCallable('db')([{ Fix: {} }]);
}
// test();