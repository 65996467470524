import React from 'react';
import { IUser } from '../if';

export const UserContext = React.createContext<IUser>({} as IUser);

export interface IUserProp {
  user: IUser;
}

function withUserContext<P extends object>(Component: React.ComponentType<P & IUserProp>): React.FC<P> {
  return (props: P) => <UserContext.Consumer>{user => <Component user={user} {...props as P} />}</UserContext.Consumer>;
}

export { withUserContext };
