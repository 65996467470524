import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IStepStateProp, IStepState } from '../appState';
import { withUserContext, IUserProp } from './UserContext';
import { TreeTable, TreeState } from 'cp-react-tree-table';
import './treetable.css';

const useStyles = makeStyles({
  root: {
    // height: 216,
    flexGrow: 1,
    // maxWidth: 400,
  },
});

type IProps = IStepStateProp
  & { stateStepDetail: IStepState }
  & IUserProp
  ;

const MOCK_DATA = [
  {
    data: { name: 'Company I', expenses: '105,000', employees: '22', contact: 'Makenzie Higgs' },
    children: [
      {
        data: { name: 'Department 1', expenses: '75,000', employees: '18', contact: 'Florence Carter' },
      },
    ]
  },
  {
    data: { name: 'Company II', expenses: '105,000', employees: '22', contact: 'Makenzie Higgs' },
    children: [
      {
        data: { name: 'Department 1', expenses: '75,000', employees: '18', contact: 'Florence Carter' },
      },
    ]
  },
];

const Steps: React.FC<IProps> = ({ state, stateStepDetail, user }) => {
  const classes = useStyles();

  const [tree, setTree] = React.useState({ treeValue: TreeState.create(MOCK_DATA) });
  const handleOnChange = (newValue: any) => {
    setTree({ treeValue: newValue });
  }
  const renderEditableCell = (row: any, name: string) => {
    return (
      <input type="text" value={row.data.contact}
        onChange={(event) => {
          row.updateData({
            ...row.data,
            [name]: event.target.value,
          });
        }} />
    );
  }
  const renderIndexCell = (row: any, name: string) => {
    return (
      <div style={{ paddingLeft: (row.metadata.depth * 15) + 'px' }}
        className={row.metadata.hasChildren ? 'with-children' : 'without-children'}>
        {(row.metadata.hasChildren)
          ? <button className="toggle-button" onClick={row.toggleChildren}></button>
          : <button className="toggle-button" onClick={row.toggleChildren} style={{ visibility: 'hidden' }}></button>
        }
        {renderEditableCell(row, name)}
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <button onClick={() => setTree((state) => ({ treeValue: TreeState.expandAll(state.treeValue), }))}>Expand all</button>
      <TreeTable
        value={tree.treeValue}
        onChange={handleOnChange}>
        {Object.keys(MOCK_DATA[0].data).map((name, ix) => <TreeTable.Column key={ix}
          renderHeaderCell={() => <span>{name}</span>}
          renderCell={(row) => ix === 0 ? renderIndexCell(row, name) : renderEditableCell(row, name)}
        />)}
      </TreeTable>
    </div>
  );
}

export default withUserContext(Steps);
