import React from 'react';
import './App.css';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab, Toolbar, Typography } from '@material-ui/core';
import Steps from './compoments/Steps';
import Step from './compoments/Step';
import { appState } from './appState';
import StepTreeTable from './compoments/StepTreeTable';
import UserProfile from './compoments/UserProfile';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }),
);

const App: React.FC = () => {
  const classes = useStyles();

  let init = {};
  try { init = JSON.parse(window.atob(window.location.hash.substr(1) || 'e30=')); } catch (e) { }
  init.toString();

  const [tab, setTab] = React.useState('Step');
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTab(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Tabs value={tab} onChange={handleTabChange} aria-label="wrapped label tabs example">
            <Tab value="Step" label="Step" />
            <Tab value="Hierarchy" label="Hierarchy" />
          </Tabs>
          <Typography variant="h6" className={classes.title}></Typography>
          <UserProfile />
        </Toolbar>
      </AppBar>
      {tab === "Step" && <>
        <Steps state={appState.steps} stateStepDetail={appState.step} />
        <Step state={appState.step} />
      </>}
      {tab === "Hierarchy" && <StepTreeTable state={appState.steps} stateStepDetail={appState.step} />}
    </div>
  );
}

export default App;
